// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.scss";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import ClientForm from "./components/ClientDetailsForm";
import ProtectedRoute from "./components/ProtectedRoute";
import Logo from "./assets/images/Logo.png";

function App() {
  return (
    <Router>
      <div className="app">
        <nav className="navbar custom-navbar">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img src={Logo} alt="FinTech Solutions" className="logo" />
              FinTech Solutions
            </Link>
            <div className="nav-buttons">
              {/* <Link to="/" className="btn btn-quote">
              Quote
              </Link> */}
              {/* <Link to="/admin-login" className="btn btn-login">
                Login
              </Link> */}
            </div>
          </div>
        </nav>

        <main className="main-content">
          <Routes>
            <Route path="/" element={<ClientForm />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
