// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // For Firestore (db)
import { getAuth } from "firebase/auth"; // For authentication
import { getAnalytics } from "firebase/analytics"; // For analytics
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCw2g3rPHxmZ_9n_XTVVPPHReXOOJ0LeB0",
  authDomain: "fintechsolutionslk-b09f8.firebaseapp.com",
  projectId: "fintechsolutionslk-b09f8",
  storageBucket: "fintechsolutionslk-b09f8.appspot.com",
  messagingSenderId: "985318850969",
  appId: "1:985318850969:web:e1f3c40dfbc7dc6353d308",
  measurementId: "G-KMGCPWDV76"
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase

const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, auth, analytics };
