import React, { useState } from "react";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import ConfirmationBox from "./ConfirmationBox";

const ClientForm = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    // lastName: "",
    contactNumber: "",
    email: "",
    // description: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleConfirm = async () => {
    alert("Form submitted successfully!");
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const clearForm = () => {
    setFormData({
      firstName: "",
      // lastName: "",
      contactNumber: "",
      email: "",
      // description: "",
    });
    setErrors({});
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.firstName) formErrors.firstName = "First Name is required";
    // if (!formData.lastName) formErrors.lastName = "Last Name is required";
    if (!formData.contactNumber)
      formErrors.contactNumber = "Contact Number is required";
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    // if (!formData.description)
    //   formErrors.description = "Description is required";

    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      await addDoc(collection(db, "clients"), formData);
      alert("Data submitted successfully!");

      const emailFormData = {
        service_id: "service_d9kokhs",
        template_id: "template_w4r2y7d",
        user_id: "Ek8Viw18CiqIra37x",
        template_params: {
          firstName: formData.firstName,
          // lastName: formData.lastName,
          contactNumber: formData.contactNumber,
          email: formData.email,
          // description: formData.description,
        },
      };

      const response = await fetch(
        "https://api.emailjs.com/api/v1.0/email/send",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(emailFormData),
        }
      );

      if (response.ok) {
        alert("Your mail is sent!");
      } else {
        const error = await response.json().catch(() => {
          alert("Oops... Something went wrong with sending the email.");
        });
        if (error) {
          alert("Oops... " + JSON.stringify(error));
        }
      }
      clearForm();
    } catch (error) {
      console.error("Error submitting data: ", error);
      alert(`Error submitting data: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-background">
      <div className="container mt-5">
        <form onSubmit={handleSubmit} className="client-form">
          <div className="header-title">
            <h2>Get Your Quote Now</h2>
          </div>
          <div className="form-group">
            <input
              name="firstName"
              placeholder="Full Name"
              value={formData.firstName}
              onChange={handleChange}
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
            />
            {errors.firstName && (
              <div className="invalid-feedback">{errors.firstName}</div>
            )}
          </div>

          {/* <div className="form-group">
            <input
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
            />
            {errors.lastName && (
              <div className="invalid-feedback">{errors.lastName}</div>
            )}
          </div> */}

          <div className="form-group">
            <input
              name="contactNumber"
              placeholder="Contact Number"
              value={formData.contactNumber}
              onChange={handleChange}
              className={`form-control ${
                errors.contactNumber ? "is-invalid" : ""
              }`}
            />
            {errors.contactNumber && (
              <div className="invalid-feedback">{errors.contactNumber}</div>
            )}
          </div>

          <div className="form-group">
            <input
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>

          {/* <div className="form-group">
            <textarea
              name="description"
              placeholder="Description"
              value={formData.description}
              onChange={handleChange}
              className={`form-control ${errors.description ? "is-invalid" : ""}`}
            />
            {errors.description && (
              <div className="invalid-feedback">{errors.description}</div>
            )}
          </div> */}

          <div className="form-buttons">
            <button
              type="button"
              className="btn btn-outline-danger clear-btn"
              onClick={clearForm}
              disabled={isSubmitting}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-primary submit-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Get a Quote"}
            </button>
          </div>
        </form>
        {showConfirmation && (
          <ConfirmationBox
            message="Do you want to submit the form?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
    </div>
  );
};

export default ClientForm;
