import React, { useState } from "react";
import "./Confirmation.scss"
import "bootstrap/dist/css/bootstrap.min.css";

const ConfirmationBox = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-box">
      <h5>Confirmation</h5>
      <div className="confirmation-message">
        {message || "Are you sure you want to proceed?"}
      </div>
      <div className="btn-group">
        <button className="btn btn-confirm" onClick={onConfirm}>
          Confirm
        </button>
        <button className="btn btn-cancel" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmationBox;
